<template>
  <div class="tabulation">
    <el-card class="chart-card">
      <!-- 搜索框 -->
      <div class="grabble">
        <div style="width: 57%">
          <el-input
            class="hunt"
            v-model="monicker"
            placeholder="请输入会员姓名"
            size="large"
            @input="orangutan"
          />
          <el-input
            class="hunt"
            v-model="encipher"
            placeholder="请输入会员编码"
            size="large"
            @input="legs"
          />
          <el-select
            class="hunt"
            v-model="ability"
            placeholder="请输入组织名称"
            size="large"
            @click="multiple"
            @change="dog"
            clearable
            ref="selectIt"
            @focus="closeIt"
          />
          <el-button style="margin-left: 10px" type="primary" size="large" @click="search1"  :loading='formative'
            ><el-icon class="cancelgu"> <Search /> </el-icon>搜索</el-button
          >
        </div>
        <!-- <div>
          <el-button
            style="margin-left: 10px"
            type="primary"
            size="large"
            @click="append"
            ><el-icon class="cancelgu"> <Plus /> </el-icon>添加</el-button
          >
        </div> -->
      </div>
      <div class="summation">
        <div  class="hint">统计 : {{altogether}}人</div>
        <div class="hint">提示 : 归档会员将不能登录智慧工会小程序，如有积分兑换需求请在公众号登录独立商城小程序进行兑换。</div>
      </div>
      <el-table
        :data="filterTableData"
        style="width: 100%"
        row-key="unionId"
        @cell-mouse-enter="obtain"
        border
        :empty-text="texts"
        :header-cell-style="{ background: '#F5F7FA' }"
      >
      <!-- 会员姓名 -->
        <el-table-column align="center" label="会员姓名" prop="name" />
        <!-- 会员编号 -->
        <el-table-column
          label="会员编码"
          prop="username"
          align="center"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 所属工会 -->
        <el-table-column
          label="所属工会"
          prop="topUnionName"
          align="center"
          :formatter="quantum"
        >
        </el-table-column>
        <!-- 所属部门 -->
        <el-table-column
          label="所属部门"
          prop="parentUnionName"
          align="center"
          :formatter="quantum"
        >
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <el-button
              size="default"
              @click="copyreader(scope.row.userId)"
              class="bulur"
              ><el-icon class="cancel"> <CircleClose /> </el-icon>取消归档</el-button
            >
            <!-- <el-button size="default" @click="expurgate" class="red"
              ><el-icon class="cancel"> <Delete /> </el-icon>删除</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 表格分页 -->
      <!-- 一页时候隐藏 hide-on-single-page="true" -->
      <el-pagination
        hide-on-single-page="true"
        layout="prev, pager, next,sizes"
        :page-sizes="[6, 12, 18, 24]"
        v-model:currentPage="currentPage"
        :page-size="pagesize"
        :total="altogether"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <!-- 删除按钮弹窗 -->
    <el-dialog v-model="remove" title="离职归档" width="25%" center>
      <div class="labor">
        <div class="sure">是否取消归档</div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="asd" size="large" type="primary" @click="affirm">确定</el-button>
          <el-button class="dsa" size="large" @click="remove=false">取消</el-button>
        </span>
      </template>
    </el-dialog>
     <!-- 所属组织弹窗 -->
    <div>
      <s3-layer v-model="visible" title="所属组织" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
        <el-tree
          :data="texture"
          :props="defaultProps"
          accordion
          @node-click="dendrogram"
        />
      </s3-layer>
    </div>
  </div>
</template>
<script>
import { Search,CircleClose} from "@element-plus/icons-vue";
import { useRouter  } from "vue-router";
import { Cancel,Abolish,ZzZlist } from "../../utils/api"
import { ref,onMounted } from "vue"
import qs from 'qs'
import { ElMessage } from "element-plus";
export default {
  name:"pigeonhole",
  setup() {
    // 搜索加载
    const formative=ref(false)
    let selectIt=ref()
    // 下拉隐藏
    let closeIt=()=>{
      selectIt.value.blur();
    }
    // 加载中
    let texts=ref('加载中')
    // 组织弹窗
    let multiple=()=>{
      visible.value=true
    }
    let visible=ref(false)
      // 会员姓名输入框改变
    // const orangutan=()=>{
    //   if(monicker.value==''&&encipher.value==''&&ability.value==''){
    //     list()
    //   }
    // }
    // 会员编号输入框改变值触发
    // const legs=()=>{
    //   if(monicker.value==''&&encipher.value==''&&ability.value==''){
    //     list()
    //   }
    // }
    // 组织名输入框改变值触发
    const dog=()=>{
      // if(monicker.value==''&&encipher.value==''&&ability.value==''){
      //   list()
      // }
      Hcode.value=''
    }
    // 搜索框
    const defaultProps = {
      children: 'unionDtoList',
      label: 'unionName',
    }
     let texture =ref([])
    const lang=()=>{
      ZzZlist().then((res)=>{
        // console.log(res)
        texture.value=res.data.data.historyUnionList
      })
    }
    // 组织名称code
    let ability=ref('')
    // 编码
    let encipher=ref('')
    // 姓名
    let monicker = ref('')
     // 获取code 
    let Hcode=ref('')
    // 弹窗树形选择
    let dendrogram = (node)=>{
      ability.value=node.unionName
      Hcode.value=node.unionCode
      console.log(node)
    }
    const search1=()=>{
      formative.value=true
      search()
    }
    const search=()=>{
      console.log(monicker.value)
      console.log(encipher.value)
      let data={
        limit:branches.value,
        page:currentPage.value,
        name:monicker.value,
        username:encipher.value,
        unionCode:Hcode.value
      }
      Cancel(qs.stringify(data)).then((res)=>{
        formative.value=false
        console.log(res)
        altogether.value=res.data.data.page.totalCount
        filterTableData.value=res.data.data.page.list
        if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
      })
    }
    // 数据总共条数
    let altogether =ref(null)
    // 弹窗
    let remove=ref(false)
    // 每页显示条数
    let currentPage = ref(1)
    let pagesize=ref(6)
    let handleSizeChange=(size)=>{
      pagesize.value=size
      branches.value=size
      if(monicker.value!==''||encipher.value!==''||ability.value!==""){
        search()
      }else{
        list()
      }
    }
    let handleCurrentChange=(size)=>{
      console.log(size)
      currentPage.value=size
      if(monicker.value!==''||encipher.value!==''||ability.value!==""){
        search()
      }else{
        list()
      }
    }
    // 取消归档列表
    onMounted(()=>{
      list()
      lang()
    })
    let branches=ref(6)
    const list=()=>{
      let data={
        limit:branches.value,
        page:currentPage.value
      }
      Cancel(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          altogether.value=res.data.data.page.totalCount
          filterTableData.value=res.data.data.page.list
          if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
        }
      })
    }
    // 确定取消归档
    const affirm=()=>{
      let data={
        userId:id.value
      }
      Abolish(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          ElMessage({
            message: "取消成功",
            type: "success",
            center: true,
          });
          if(monicker.value!==''||encipher.value!==''||ability.value!==""){
            search()
          }else{
            list()
          }
          remove.value=false
        }else{
          ElMessage({
            message: res.data.msg,
            type: "warning",
            center: true,
          });
          if(monicker.value!==''||encipher.value!==''||ability.value!==""){
            search()
          }else{
            list()
          }
          remove.value=false
        }
      })
    }
    // 编辑跳转
    let id =ref('')
    const copyreader = (o)=>{
      remove.value=true
      id.value=o
      console.log(o)
    }
    // 路由跳转
    const router = useRouter()
    // 头部添加跳转
    let append=()=>{
      router.push('/inform')
    }
    // 列表数据循环
    const filterTableData = ref([])
    return {
      // 搜索加载
      search1,
      formative,

      branches,
      selectIt,
      closeIt,
      texts,
      dendrogram,
      defaultProps,
      texture,
      multiple,
      visible,
      ability,
      encipher,
      monicker,
      altogether,
      search,
      dog,
      // legs,
      // orangutan,
      affirm,
      remove,
      handleCurrentChange,
      pagesize,
      handleSizeChange,
      currentPage,
      // 编辑跳转
      copyreader,
      // 头部组织添加跳转
      append,
      // 循环列表数据
      filterTableData
    };
  },
  components: {
    Search,
    CircleClose
  },
};
</script>
<style lang="scss" scoped>
::v-deep .dsa{
  margin-left: 10px !important;
}
::v-deep .asd{
  margin-right: 10px !important;
}
::v-deep .chart-card::-webkit-scrollbar{
  display:none
}
.chart-card{
  height: calc(100vh - 145px);
  overflow: auto;
}
.summation{
  display: flex;
  justify-content: space-between;
}
::v-deep  .el-table__empty-block{
  border-bottom: 1px solid #EBEEF5 !important;
}
.hint{
  font-size: 14px;
  color: #909399;
  // font-weight: bold;
  margin-bottom: 15px;
}
::v-deep .el-table__body {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
::v-deep .el-table__header {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.hunt {
  width: 22%;
  margin-right: 10px;
}
.grabble {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.el-table__header {
  margin-top: 0;
  margin-bottom: 0;
}
.red {
  color: red;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.red:hover {
  color: #fab6b6;
}
.bulur {
  color: #409eff;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.bulur:hover {
  color: #a0cfff;
}
// .el-button{
//   border: none;
//   --el-button-bg-color:none;
//   --el-button-hover-bg-color:none;
//   --el-button-hover-text-color:none;
// }
.el-scrollbar__thumb {
  background-color: #ffffff;
}
.items {
  display: flex;
}
.el-select .el-input__inner {
  width: 183px;
}
::v-deep .el-dialog--center .el-dialog__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-select-dropdown__empty {
  display: none !important  ;
}
.el-select-dropdown__item {
  background-color: #ffffff;
  height: 40px !important;
}
.el-select-dropdown__item {
  overflow: visible;
}
.labor {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tianjia {
  margin-left: 20px;
}
.cancel {
  margin-right: 5px;
  font-weight: 400;
}
.cancelgu {
  margin-right: 6px;
  font-weight: bold;
}
::v-deep .el-scrollbar__thumb {
  display: none !important;
}
::v-deep .el-table__inner-wrapper::before {
  width: 0;
}
::v-deep .el-table .el-table__cell {
  padding-top: 15px;
  padding-bottom: 15px;
}
.el-card.is-always-shadow {
  width: 95%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
::v-deep .el-card__body {
  padding: 0;
}
.el-pagination {
  margin-top: 10px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tabulation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
</style>
<style>
.el-popper {
  z-index: 10000 !important;
}
.modality {
  height: 150px !important;
}

.el-select-dropdown__item.selected {
  font-weight: 500 !important;
}
</style>
